import path from "path"
import { createUrl, PathRegExp } from "@marvinh/path-to-regexp"
import pathsMapping from "config/paths-mapping.json"

import type { Nullish, ParseUrlParams } from "~/@types/generics"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { getAllDimensions } from "~/lib/i18n/utils/get-all-dimensions"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { isLocale } from "~/lib/i18n/utils/is-locale"
import { getPropertyFromGID } from "~/lib/shopify/utils/id"
import { SB_PAGES, type SbPagesComponent } from "~/lib/storyblok/constants"
import type { SbLinkFromSchema, ShopifyLinkFromSchema } from "~/components/ui/Link/_data/schema"
import { isSbLink, isShopifyLink } from "~/components/ui/Link/utils/is-link"

export function hrefResolver(link: SbLinkFromSchema | ShopifyLinkFromSchema, locale: Nullish<string>) {
  const processedLocale = getProcessedLocale(locale)
  if (isShopifyLink(link)) {
    if (!link?.id || !link?.handle) return null

    const property = getPropertyFromGID(link?.id)

    switch (property) {
      case "Product":
        return getPath(processedLocale, "products/:slug", { slug: link?.handle })
      case "Collection":
        return getPath(processedLocale, "collections/:slug", { slug: link?.handle })
      default:
        return null
    }
  }

  if (isSbLink(link)) {
    if (!link.story) {
      return null
    }

    return resolveSbStory(link.story, locale)
  }
}

function removeDimensionFromSlug(slug?: string) {
  if (!slug) return ""
  const splitedSlug = slug.split("/")
  const computedDimension = splitedSlug[0]

  if (!computedDimension) return slug

  if (getAllDimensions().includes(computedDimension)) {
    return splitedSlug.slice(1).join("/")
  }

  return slug
}

export function getPath<Route extends string, Params extends ParseUrlParams<Route>>(
  locale: Nullish<string>,
  route: Route,
  params: Params
) {
  const processedLocale = isLocale(locale) ? locale : DEFAULT_LOCALE

  const processedRoute =
    (pathsMapping as Record<string, Record<string, { source: string; destination: string }>>)?.[route]?.[
      processedLocale
    ]?.source ?? route

  const pathRegExp = new PathRegExp(processedRoute)
  const endPath = createUrl(pathRegExp, params)

  return path.join("/", processedLocale, endPath).replace(/\/$/, "")
}

export function resolveSbStory(story: SbLinkFromSchema["story"], locale: Nullish<string>): string | null {
  const processedLocale = getProcessedLocale(locale)

  const translatedSlug = story?.translated_slugs?.find((slug) => slug.lang === processedLocale) ?? {
    path: story?.default_full_slug,
  }
  const pageComponent = story?.content.component as SbPagesComponent
  let slug
  switch (pageComponent) {
    case "home_page":
      return getPath(processedLocale, "/", {})
    case "universal_page":
      slug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(processedLocale, "/:slug", { slug: removeDimensionFromSlug(slug) })
    case "configurator_page":
      slug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(processedLocale, "/configurator/:slug", { slug: removeDimensionFromSlug(slug) })
    case "authentication_page":
      return getPath(locale, "/account/:slug", { slug: removeDimensionFromSlug(story?.slug) })
    case "addresses_page":
      return getPath(locale, "/account/addresses", {})
    case "account_informations_page":
      return getPath(locale, "/account", {})
    case "orders_page":
      return getPath(locale, "/account/orders", {})
    case "legals_page":
      slug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(locale, "/legals/:slug", { slug: removeDimensionFromSlug(slug) })
    case "faq_page":
      slug = translatedSlug?.path?.replace(SB_PAGES[pageComponent].rootSlug, "") ?? story?.slug
      return getPath(locale, "/faq/:slug", { slug: removeDimensionFromSlug(slug) })
    case "search_page":
      return getPath(locale, "/search", {})
    case "contact_page":
      return getPath(locale, "/contact", {})
    default:
      return null
  }
}
