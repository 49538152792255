import { z } from "zod"

import type { Nullish } from "~/@types/generics"
import type { SbSchemaOptions } from "~/lib/storyblok/schemas"
import { sbAsset } from "~/lib/storyblok/schemas/helpers"
import { zodFallback } from "~/lib/zod/utils/zod-fallback"
import { assetMetafields, getAltByLocale, type TImage } from "~/components/ui/Image/_data/serializer"

export function sbAssetImageSchema(options: SbSchemaOptions) {
  return sbAsset()
    .extend({
      alt: z.string().optional(),
      filename: z.string().url(),
      focus: z.string().optional(),
      meta_data: z
        .object({
          [assetMetafields.en_alt]: z.string().optional(),
        })
        .optional(),
    })
    .or(zodFallback(null))
    .transform<TImage | null>((data) => {
      if (!data || !data.filename) return null

      const dimensions = getDimensionsFromFilename(data.filename)
      if (!dimensions.width || !dimensions.height) return null

      const altText = getAltByLocale(data, options.locale)

      return {
        ...dimensions,
        focus: getFocus(data.focus, dimensions),
        alt: altText ?? "",
        src: data.filename,
      }
    })
}

export type SbImage = z.infer<ReturnType<typeof sbAssetImageSchema>>

export function getDimensionsFromFilename(filename: string) {
  const dimensions = filename?.split("/")?.[5]?.split("x")
  const width = dimensions?.[0] ? parseInt(dimensions[0]) : undefined
  const height = dimensions?.[1] ? parseInt(dimensions[1]) : undefined

  return {
    width,
    height,
  }
}

export function getFocus(
  focus: Nullish<string>,
  { width, height }: { width: Nullish<number>; height: Nullish<number> }
) {
  const [x, y] = focus?.split(":")[0]?.split("x") ?? [undefined, undefined]

  if (!x || !y || !width || !height) return null

  return {
    x: (parseFloat(x) / width) * 100,
    y: (parseFloat(y) / height) * 100,
  }
}
