import type { ComponentProps } from "react"
import clsx from "clsx"

import { isPablo } from "~/lib/brand"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { DIRECTIONS, type Direction } from "~/styles/variables/directions"

import AccountPablo from "./assets/account_pablo.svg"
import Account from "./assets/account.svg"
import Arrow from "./assets/arrow-right.svg"
import ArrowSliderLeft from "./assets/arrow-slider-left.svg"
import ArrowSliderRight from "./assets/arrow-slider-right.svg"
import AuthApple from "./assets/auth_apple.svg"
import AuthFacebook from "./assets/auth_facebook.svg"
import AuthGoogle from "./assets/auth_google.svg"
import Bell from "./assets/bell.svg"
import Chat from "./assets/chat.svg"
import Chevron from "./assets/chevron-right.svg"
import ChevronVertical from "./assets/chevron-top.svg"
import Close from "./assets/close.svg"
import Envelope from "./assets/envelope.svg"
import Facebook from "./assets/facebook.svg"
import Grid1 from "./assets/grid-1.svg"
import Grid2 from "./assets/grid-2.svg"
import Grid3 from "./assets/grid-3.svg"
import Grid4 from "./assets/grid-4.svg"
import GridMobile from "./assets/grid-mobile.svg"
import Instagram from "./assets/instagram.svg"
import LogoFooterPablo from "./assets/logo_footer_pablo.svg"
import Logo from "./assets/logo.svg"
import MagnifyingGlassPablo from "./assets/magnifying-glass_pablo.svg"
import MagnifyingGlass from "./assets/magnifying-glass.svg"
import Menu from "./assets/menu.svg"
import Minus from "./assets/minus.svg"
import MoreHorizontal from "./assets/more-horizontal.svg"
import Pause from "./assets/pause.svg"
import Phone from "./assets/phone.svg"
import Pin from "./assets/pin.svg"
import Play from "./assets/play.svg"
import Plus from "./assets/plus.svg"
import ShoppingBagPablo from "./assets/shopping-bag_pablo.svg"
import ShoppingBag from "./assets/shopping-bag.svg"
import Tick from "./assets/tick.svg"
import VolumeMax from "./assets/volume-max.svg"
import VolumeOff from "./assets/volume-off.svg"
import * as css from "./styles.css"

export type DynamicIconProps = ComponentProps<"svg"> & { className?: string; title?: string }

export const ICONS = {
  Account: isPablo ? AccountPablo : Account,
  Arrow,
  ArrowSliderRight,
  ArrowSliderLeft,
  Bell,
  Chat,
  Chevron,
  ChevronVertical,
  Tick,
  Close,
  Envelope,
  Grid1,
  Grid2,
  Grid3,
  Grid4,
  GridMobile,
  Logo,
  LogoFooterPablo,
  MagnifyingGlass: isPablo ? MagnifyingGlassPablo : MagnifyingGlass,
  Menu,
  Plus,
  Minus,
  ShoppingBag: isPablo ? ShoppingBagPablo : ShoppingBag,
  MoreHorizontal,
  Phone,
  Pin,
  Facebook,
  Instagram,
  Play,
  Pause,
  VolumeOff,
  VolumeMax,
  AuthFacebook,
  AuthGoogle,
  AuthApple,
} as const

/**
 * When adding a new directional Icon, make sure it is oriented to the right!
 * e.g. Chevron: dynamic<DynamicIconProps>(() => import("./assets/chevron-right.svg")),
 */
export interface IconProps extends DynamicIconProps {
  name: keyof typeof ICONS
  direction?: Direction
  theme?: Sprinkles["color"]
  sprinklesCss?: Sprinkles
}

function Icon({ className, name, direction = DIRECTIONS.LEFT, theme = "black", sprinklesCss, ...props }: IconProps) {
  const Component = ICONS?.[name] ?? null

  return (
    Component && (
      <Component
        data-comp={"Abstracts/Icon"}
        className={clsx(css.Icon, css[direction], className, sprinkles({ ...sprinklesCss, color: theme }))}
        {...props}
      />
    )
  )
}

export default Icon
