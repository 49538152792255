import { forwardRef, useCallback, useState, type CSSProperties, type ForwardedRef } from "react"
import NextImage, { type ImageLoader, type ImageProps as NextImageProps } from "next/image"

import { Ratio } from "~/components/abstracts/Ratio"

import type { Screens } from "~/styles/variables/breakpoints"

import { useSizesFromBreakpoints, type Sizes } from "./maths"

export type AbstractImageProps = Omit<NextImageProps, "src" | "width" | "height" | "objectFit" | "alt"> & {
  sizesFromBreakpoints?: Sizes
  width?: number
  height?: number
  src: string
  ratio?: string
  alt?: string
  asPlaceholder?: boolean
  imageClassName?: string
  placeholderClassName?: string
  screens: Screens
  imageLoader?: ImageLoader
  style?: CSSProperties
}

function ImageForwarded(
  {
    sizes,
    sizesFromBreakpoints,
    className,
    imageClassName,
    asPlaceholder = false,
    onLoadingComplete,
    ratio,
    alt,
    onClick,
    placeholderClassName,
    screens,
    imageLoader,
    style,
    priority,
    width,
    height,
    ...props
  }: AbstractImageProps,
  ref?: ForwardedRef<HTMLDivElement>
) {
  const processedSizes = useSizesFromBreakpoints(sizesFromBreakpoints ?? [], props.src, screens)

  const [loaded, setLoaded] = useState(false)

  const onLoadingCompleteCallback = useCallback(
    (img: HTMLImageElement) => {
      onLoadingComplete?.(img)

      asPlaceholder && setLoaded?.(true)
    },
    [onLoadingComplete, setLoaded, asPlaceholder]
  )

  const placeholderStyle: CSSProperties = {
    zIndex: 1,
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    transition: "opacity 0.1s ease",
    opacity: loaded ? 0 : 1,
  }

  const basicProps = {
    ref,
    className,
    onClick,
    style,
  }

  const hasDimensions = Boolean(width && height)

  const renderChildren = (initialStyle: CSSProperties | null) => (
    <>
      {asPlaceholder && !priority && <span className={placeholderClassName ?? undefined} style={placeholderStyle} />}

      <NextImage
        data-comp="Abstracts/Image"
        className={imageClassName}
        sizes={processedSizes ? processedSizes : sizes ?? undefined}
        loader={imageLoader}
        alt={alt ?? ""}
        onLoadingComplete={onLoadingCompleteCallback}
        // Spread default inline style from nextjs and add custom style prop (to allow use of objectFit)
        style={{ ...(initialStyle ?? {}), ...(style ?? {}) } ?? undefined}
        priority={priority}
        {...props}
        {...(hasDimensions ? { width, height } : { fill: true })}
      />
    </>
  )

  return props.src ? (
    ratio ? (
      <Ratio {...basicProps} ratio={ratio}>
        {renderChildren}
      </Ratio>
    ) : (
      <div {...basicProps}>{renderChildren(null)}</div>
    )
  ) : null
}

export const AbstractImage = forwardRef<HTMLDivElement, AbstractImageProps>(ImageForwarded)

export type { LoaderParams } from "./loader"
export type { Size, Sizes } from "./maths"
