export const breakpoints = {
  base: 0,
  xxxs: 350,
  xxs: 480,
  xs: 570,
  sm: 640,
  md: 760,
  l: 930,
  lg: 1020,
  xl: 1280,
  max: 1920,
} as const

type Screens = typeof breakpoints
type Breakpoint = keyof typeof breakpoints

export { type Breakpoint, type Screens }
