"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import { getDimensionsFromFilename, type SbImage } from "~/lib/storyblok/schemas/default/assets/image"
import useLocale from "~/hooks/useLocale"
import { Image } from "~/components/ui/Image"
import { Link, type LinkProps } from "~/components/ui/Link"
import { getPath } from "~/components/ui/Link/utils/href-resolver"
import Icon, { type IconProps } from "~/components/abstracts/Icon"

import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type InlineCtaVariants = RecipeVariants<typeof css.inlineCta>
export type InlineCtaProps = InlineCtaVariants & { icon?: IconProps; iconFromSb?: SbImage } & LinkProps

function InlineCta({
  className,
  children,
  color,
  withLine,
  withDefaultLine,
  icon,
  iconFromSb,
  withHover = true,
  size = "default",
  weight = "regular",
  active,
  ...rest
}: InlineCtaProps) {
  const locale = useLocale()
  // -------
  // force collecion home to homepage
  // todo : add type page on storyblok to overite collection and clean this

  if (rest?.href === `/${locale}/collections/frontpage`) {
    // const currentLocale = getProcessedLocale(params.locale)
    rest.href = getPath(locale, "/", {})
  }

  return (
    <Link
      className={clsx(
        className,
        text,
        css.inlineCta({
          size,
          color,
          weight,
          withLine: !Boolean(iconFromSb) && withLine,
          withDefaultLine: !Boolean(iconFromSb) && withDefaultLine,
          withHover,
          active,
          disabled: rest.disabled,
          withIcon: Boolean(icon),
          withIconFromSb: Boolean(iconFromSb),
        })
      )}
      {...rest}
    >
      {icon || iconFromSb
        ? (props) => (
            <>
              {icon && <Icon className={clsx(css.icon)} {...icon} />}
              {iconFromSb && (
                <Image
                  width={getDimensionsFromFilename(iconFromSb.src).width}
                  height={getDimensionsFromFilename(iconFromSb.src).height}
                  className={clsx(css.icon)}
                  {...iconFromSb}
                />
              )}
              <div className={css.children}>{typeof children === "function" ? children(props) : children}</div>
            </>
          )
        : children}
    </Link>
  )
}

export default InlineCta
