import { match } from "ts-pattern"

import type { Nullish } from "~/@types/generics"
import { getLang } from "~/lib/i18n/utils/get-i18n"
import { getFocus } from "~/lib/storyblok/schemas/default/assets/image"
import type { Coords } from "~/components/ui/Image"
import { type ImageFromSchema } from "~/components/ui/Image/_data/schema"
import type { TMedia } from "~/components/ui/ProductHeader/components/ProductMedias/_data/serializer"
import imageLoader, { type LoaderParams } from "~/components/abstracts/Image/loader"

export type TImage = {
  src: string
  alt: string
  width?: number
  height?: number
  unoptimized?: boolean
  focus?: Nullish<Coords>
}

export const assetMetafields = {
  en_alt: "en_alt",
}
export function isImageFromMedia(data: TMedia): data is TImage {
  return "src" in data
}

export function serializeImages(
  images: ImageFromSchema[],
  locale: Nullish<string>,
  loaderParams?: LoaderParams
): TImage[] {
  return images.map((image) => serializeImage(image, locale, loaderParams)).filter(Boolean)
}

export default function serializeImage(
  image: Nullish<ImageFromSchema>,
  locale: Nullish<string>,
  loaderParams?: LoaderParams
): TImage | null {
  if (!image) return null

  const src = "filename" in image ? image.filename : image.url

  const alt = getAltByLocale(image, locale)

  if (!src) return null

  const dimensions = getImageDimensions(src, image)

  const isSvg = src?.indexOf(".svg") !== -1

  function getNewImageSrc(src: string) {
    if (!isSvg || !loaderParams) {
      return src
    }

    const { src: paramsSrc, ...loaderParamsWithoutSrc } = loaderParams

    return imageLoader({
      src: paramsSrc ?? src,
      ...loaderParamsWithoutSrc,
    })
  }

  return {
    src: getNewImageSrc(src) ?? src,
    width: dimensions?.width ?? undefined,
    height: dimensions?.height ?? undefined,
    focus: "focus" in image ? getFocus(image?.focus, dimensions) : undefined,
    alt: alt ?? "",
    ...(isSvg ? { unoptimized: true, width: 0, height: 0 } : {}),
  }
}

function getImageDimensions(src: string, image: ImageFromSchema) {
  const dimensions = src.split("/")[5]?.split("x")
  const widthFromSrc = dimensions?.[0] ? parseInt(dimensions[0]) : NaN
  const heightFromSrc = dimensions?.[1] ? parseInt(dimensions[1]) : NaN

  return {
    width: "width" in image ? image.width : isNaN(widthFromSrc) ? undefined : widthFromSrc,
    height: "height" in image ? image.height : isNaN(heightFromSrc) ? undefined : heightFromSrc,
  }
}

export function serializeOptionalImage(
  image: Nullish<Parameters<typeof serializeImage>[0]>,
  locale: Nullish<string>
): TImage | null {
  if (!image) return null
  return serializeImage(image, locale)
}

export function getAltByLocale(
  image: Parameters<typeof serializeImage>[0],
  locale: Parameters<typeof serializeImage>[1]
) {
  const baseAlt = image && "alt" in image ? image.alt : image && "altText" in image ? image?.altText : null

  const altText = match(getLang(locale))
    .with("fr", () => baseAlt)
    .with("en", () =>
      image && "meta_data" in image && image.meta_data ? image.meta_data[assetMetafields.en_alt] : baseAlt
    )
    .otherwise(() => (image && "alt" in image ? image.alt : ""))

  return altText ?? ""
}
