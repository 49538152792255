"use client"

import { forwardRef, type ComponentProps } from "react"
import { assignInlineVars } from "@vanilla-extract/dynamic"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import { isShopifyPNGImage } from "~/lib/shopify/utils/is-shopify-png-image"
import { AbstractImage } from "~/components/abstracts/Image"
import imageLoader from "~/components/abstracts/Image/loader"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

export type Coords = {
  x: number
  y: number
}

export interface ImageProps extends Omit<ComponentProps<typeof AbstractImage>, "screens" | "ratio"> {
  noBackground?: boolean
  ratio?: Sprinkles["ratio"]
  focus?: Nullish<Coords>
}

export const Image = forwardRef<HTMLDivElement, ImageProps>(
  ({ className, noBackground = false, ratio, imageClassName, style, focus, ...props }, ref?) => (
    <AbstractImage
      ref={ref}
      className={clsx(css.ImageContainer, className, {
        [css.backgroundColor]: !noBackground && isShopifyPNGImage(props.src),
      })}
      imageClassName={clsx(css.Image({ cover: Boolean(ratio || props.fill) }), imageClassName)}
      placeholderClassName={css.backgroundColor}
      loader={imageLoader}
      draggable={false}
      screens={breakpoints}
      ratio={ratio ? sprinkles({ ratio }) : undefined}
      style={{
        ...style,
        ...(focus
          ? assignInlineVars(css.imageVars, {
              focus: { x: `${focus.x}%`, y: `${focus.y}%` },
            })
          : {}),
      }}
      {...props}
    />
  )
)

export type { Sizes } from "@unlikelystudio/react-abstract-components"
export type { imageLoader }
