import imageLoaderShopify, { HOSTNAME as HOSTNAME_SHOPIFY } from "~/components/abstracts/Image/loader/shopify"
import imageLoaderStoryblok, { HOSTNAME as HOSTNAME_STORYBLOK } from "~/components/abstracts/Image/loader/storyblok"
import imageLoaderVimeo, { HOSTNAME as HOSTNAME_VIMEO } from "~/components/abstracts/Image/loader/vimeo"

export interface LoaderParams {
  src: string
  quality?: number
  width: number
  rect?: string
}

const LOADERS: Record<string, (params: LoaderParams) => string> = {
  [HOSTNAME_STORYBLOK]: imageLoaderStoryblok,
  [HOSTNAME_SHOPIFY]: imageLoaderShopify,
  [HOSTNAME_VIMEO]: imageLoaderVimeo,
}

const REGEX = /^https?:\/\/([^\/]+)/

export default function imageLoader(params: LoaderParams) {
  // Check if it's an SVG
  if (params.src.indexOf(".svg") !== -1) return params.src

  // Get Hostname
  const result = REGEX.exec(params.src)
  if (!result || !result?.[1]) return params.src

  return LOADERS?.[result[1]]?.(params) ?? params.src
}
