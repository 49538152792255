import { z } from "zod"

import type { SbSlice } from "~/lib/storyblok/schemas"

export function sbComponent<Name extends string>(name: Name) {
  return z
    .object({
      _uid: z.string(),
      component: z.literal(name),
    })
    .describe(`Storyblok component "${name}"`)
}

export function sbPlugin<Name extends string>(name: Name) {
  return z
    .object({
      plugin: z.literal(name),
    })
    .describe(`Storyblok plugin "${name}"`)
}

export function sbAsset() {
  return z.object({
    fieldtype: z.literal("asset"),
    id: z.number().nullable(),
  })
}

export function sbSlice<Data extends { component: string }, Input, Output>(
  schema: z.Schema<Data, z.ZodTypeDef, Input>,
  reshape: (data: Data) => Output
) {
  return schema.transform((data) => {
    if (!data) return null

    return { name: data.component, data: reshape(data) } as SbSlice<Output, Data["component"]>
  })
}
